import React, { useRef, forwardRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageTransform = forwardRef(({ imageUrl, loaded, setLoaded, selectedDate, handleEvent }, ref) => {
    const transformWrapperRef = useRef(null);
    const instance = transformWrapperRef.current;
    const [initialScale, setInitialScale] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            const imageWidth = img.width;  // Natural width of the image
            const imageHeight = img.height; // Natural height of the image
            const viewportWidth = window.innerWidth; // Viewport width
            const viewportHeight = window.innerHeight; // Viewport height
      
            // Target width is 80% of the viewport width
            const targetWidth = viewportWidth * 0.8;
      
            // Calculate scale factor based on width
            const widthScaleFactor = targetWidth / imageWidth;
      
            // To allow for some zooming in, set a minimum scale
            const zoomInFactor = 3.5; // This factor can be adjusted
            const scaleFactor = Math.max(widthScaleFactor, zoomInFactor);
      
            /*    console.log({
              imageWidth,
              imageHeight,
              viewportWidth,
              viewportHeight,
              targetWidth,
              widthScaleFactor,
              scaleFactor,
            }); */
      
          setInitialScale(scaleFactor);
        // Center the image in the container
/*         if (instance) {
            const xOffset = (viewportWidth - (imageWidth * scaleFactor)) / 2;
            const yOffset = (viewportHeight - (imageHeight * scaleFactor)) / 2;
            instance.setTransform(xOffset, yOffset, scaleFactor);
        } */
        };
      }, [imageUrl]);

    return (  
        <div style={{ 
            position: 'relative', 
            display: 'flex',          // Flexbox to align content
            justifyContent: 'center', // Center horizontally
            alignItems: 'center',      // Center vertically 
        }}> {/*        {
            loaded ? null :<div style={{width: '40%'}}>&nbsp;</div> } */}
        {
            loaded ? null :
            <img 
                src='rediff.svg' 
                height={250} width={250}
                alt={`Loading...`} 
                style={{
                    position: 'absolute', 
                    top: '0', 
                    padding: '20px',
                    width: window.innerWidth < 932 ? '150px' : '250px',
                    height: window.innerWidth < 932 ? '150px' : '250px',}}
            />
        } 
        <TransformWrapper 
            ref={transformWrapperRef}
            wheel={{ step: 2 }} 
            pinch={{ step: 5 }}
            key={initialScale}
            centerZoomedOut={true}
            initialScale={initialScale}
            options={{
                limitToBounds: false,  // Allow panning beyond bounds
                minScale: 0.5,           // Start at a zoom level where the image is at least as big as its container
                maxScale: 50,           // Set maximum zoom level
                //centerContent: true,    // Ensures image is centered initially
            }}
        >
        
        <TransformComponent 
            contentClass='main' 
            wrapperStyle={{ height: '75vh', width: '100vw',
                display: 'flex',   // Flexbox to align content
                overflow: 'hidden',  // Ensure no scrollbars appear
                }}
        > 
            <img
                src={imageUrl}
                alt={`UKTV Listing ${selectedDate}`}                    
                onLoad={() => setLoaded(1)}
                style={loaded ? { 
                    maxWidth: '100%',
                    maxHeight: '70vh',
                    objectFit: 'contain'
                } : { display: 'none' }}
            />
        </TransformComponent>
        {/* Zoom Buttons */ }
        <div className="zoom-buttons">
        <button
        className="zoom-button"
        onClick={() => {
            handleEvent(`ZoomIn pressed`);    // Call handleEvent with 'Zoom In' as text
            instance.zoomIn();         // Call zoomIn function
        }}
        >+</button>
            <button         
            className="zoom-button"
            onClick={() => {
            handleEvent(`ZoomReset pressed`); 
            instance.resetTransform(); 
        }}>&#183;</button>
            <button         
            className="zoom-button"
            onClick={() => {
            handleEvent(`ZoomOut pressed`); 
            instance.zoomOut(); 
        }}>-</button>
        </div>
        </TransformWrapper>
            {/* Bottom Container for small screens */}
        <div style={{
            width: '100%',
            height: '10vh',          // Height for the bottom container
            backgroundColor: '#f1f1f1',
            textAlign: 'center',
            display: 'none',         // Initially hidden
        }}>
            {/* Responsive styling for small screens */}
            <p>Bottom Container Content</p>
        </div>

        <style jsx>{`
            @media (max-width: 600px) {
                div[style*="height: 10vh"] {
                    display: block;
                }
            }
        `}</style>
        </div>
  );
});

export default ImageTransform;